<template>
  <div>
    <div v-if="editing" class="d-flex align-end flex-wrap flex-md-row justify-space-between">
      <v-row no-gutters align="center" class="editing-form">
        <v-col cols="auto">
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <span class="font-weight-medium black--text">
                {{ $t('campaign.rules.rules_for') }}
              </span>
            </v-col>
            <v-col cols="auto">
              <c-select
                :value="rule.action"
                :items="campaignUpdatingRuleTypes"
                class="primary--text custom-select"
                hide-details
                :outlined="false"
                height="30"
                background-color="white"
                item-slot
                selection-slot
                @change="setRule($event, 'action')"
              >
                <template #selection="{ item }">
                  <div class="text-decoration-underline primary--text">
                    {{ $t(`campaign.rules.types.${item}`) }}
                  </div>
                </template>
                <template #item="{ item }">
                  {{ $t(`campaign.rules.types.${item}`) }}
                </template>
              </c-select>
            </v-col>
            <v-col cols="auto">
              <span class="font-weight-medium black--text mr-2">{{ $t('campaign.rules.within') }}</span>
            </v-col>
            <v-col cols="auto">
              <v-sheet width="45" class="mr-2">
                <c-text-field
                  :value="rule.periodDays"
                  type="number"
                  height="24"
                  hide-details
                  placeholder="3"
                  :error-messages="errors.period_days"
                  background-color="white"
                  border-color="secondary"
                  @keypress="integerFilter($event)"
                  @focus="$emit('clear-error', 'period_days')"
                  @input="setRule($event, 'periodDays')"
                />
              </v-sheet>
            </v-col>
            <v-col cols="auto">
              <span class="font-weight-medium black--text mr-2">{{ $t('campaign.rules.days') }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-for="(condition, idx) in rule.conditions" :key="idx" :cols="idx === 0 ? 'auto' : 12">
          <condition-item
            :rule="condition"
            :errors="errors"
            :current-rule-index="idx"
            :total-conditions-length="rule.conditions.length"
            :class="{ 'mt-3': idx !== 0 }"
            :can-adding-condition="canAddingConditions && idx === rule.conditions.length - 1"
            @add-condition="addCondition()"
            @update-condition="updateCondition($event)"
            @delete-condition="deleteCondition($event)"
            @clear-error="$emit('clear-error', $event)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="end" class="flex-nowrap">
        <v-col cols="auto">
          <c-btn text color="error" @click="cancel()">
            <span class="text-decoration-underline">{{ $t('main.cancel') }}</span>
          </c-btn>
        </v-col>
        <v-col cols="auto">
          <c-btn color="primary" depressed :label="$t('main.save')" @click="save()" />
        </v-col>
      </v-row>
    </div>
    <div v-else class="d-flex justify-space-between">
      <v-sheet max-width="670" class="pa-0">
        <span class="font-weight-medium black--text">{{ $t('campaign.rules.rules_for') }} {{ $t(`campaign.rules.types.${currentRule.action}`) }} {{ $t('campaign.rules.within') }}
          {{ currentRule.periodDays }} {{ $t(`campaign.rules.days`) }}
          <span v-for="(ruleItem, index) in currentRule.conditions" :key="index">
            {{ $t('campaign.rules.and') }} {{ $t('campaign.rules.if') }}
            {{ $t(`campaign.rules.fields.${ruleItem.field}`) }} {{ $t('campaign.rules.is') }}
            {{ $t(`campaign.rules.operations.${ruleItem.operation}`) }}
            {{ $t('campaign.rules.than') }} {{ ruleItem.value }}
          </span>
        </span>
      </v-sheet>
      <div class="d-flex">
        <c-btn
          icon
          small
          :disabled="disabled"
          color="info"
          :icon-props="{
            icon: '$pencil',
            size: 14
          }"
          @click="$emit('update', currentRule.id)"
        />
        <c-btn
          icon
          small
          :disabled="disabled"
          color="error"
          :icon-props="{
            icon: '$trash',
            size: 14
          }"
          @click="$emit('delete', currentRule.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import Rule from '@/services/classes/Rule.js'
  import ConditionItem from '@/views/NewCampaign/RulesPanel/ConditionItem.vue'

  export default {
    name: 'RuleItem',
    components: {
      CTextField, CBtn, CSelect, ConditionItem
    },
    props: {
      currentRule: {
        type: Rule,
        required: true
      },
      errors: {
        type: Object,
        required: true
      },
      ruleOptions: {
        type: Object,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        rule: new Rule()
      }
    },
    computed: {
      ...mapState('settings', [
        'campaignUpdatingRuleTypes',
        'campaignUpdatingRuleConditionFields',
        'maxCampaignUpdatingRuleConditions'
      ]),
      canAddingConditions() {
        return this.rule.conditions.length < this.maxCampaignUpdatingRuleConditions
      }
    },
    created() {
      this.rule.updateRule(this.currentRule)
    },
    methods: {
      setRule(event, field) {
        this.rule.updateRuleProperty(field, event)
      },
      addCondition() {
        this.rule.addSubRule({
          field: this.ruleOptions.rule.field,
          operation: this.ruleOptions.rule.operation,
          value: this.ruleOptions.rule.value
        })
      },
      integerFilter(event) {
        if (!/^-?\d*$/.test(event.key)) event.preventDefault()
      },
      deleteCondition(index) {
        this.rule.deleteCondition(index)
      },
      updateCondition({ index, data }) {
        this.rule.updateCondition(index, data)
      },
      cancel() {
        this.rule.updateRule(this.currentRule)
        this.$emit('cancel')
      },
      save() {
        this.currentRule.updateRule(this.rule)
        this.$emit('save')
      }
    }
  }
</script>

<style scoped lang="scss">
.custom-select ::v-deep .v-select__selections input{
  display: none;
}
.editing-form {
  gap: 8px;
}
</style>
